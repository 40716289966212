import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useState, useEffect } from "react";
import { db } from "../../firebase/config";
import { useFirestore, useFirestoreGeneral } from "../../firebase/useFirestore";
import firebase from "firebase";

const PeriodSelector = ({page, reportId}) => {
    // State
    const [showRangePicker, setShowRangePicker] = useState(false);
    const [docid, setDocid] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    // Hooks
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const collection = page === 'dashboard' ? 'DashboardSettings' : 'LiveReports';

    // Firestore
    const dashboardSettings = useFirestore("DashboardSettings");
    const report = useFirestoreGeneral('LiveReports', 'ID', reportId ? reportId : '123')

  // Set the start date and end date from the dashboard settings or the report settings
  useEffect(() => {

    // Set the start date and end date from the dashboard settings
    page === 'dashboard' && dashboardSettings && dashboardSettings.forEach(item => {
        setDocid(item.docid);
        setStartDate(item.Start);
        setEndDate(item.End);
    });

    // Set the start date and end date from the report settings
    page === 'livereport' && report && report.forEach(item => {
        setDocid(item.docid);
        setStartDate(item.Start);
        setEndDate(item.End);
    });

}, [dashboardSettings, report]);

// Helper function to save the selected date range
  const saveRange = () => {

    if (!docid) return;

    db.collection(collection)
    .doc(docid)
    .update({
        End: firebase.firestore.Timestamp.fromDate(
        new Date(new Date(selectedEndDate).setHours(23, 59, 59, 999))
        ),
        Start: firebase.firestore.Timestamp.fromDate(new Date(selectedStartDate)),
    });
  };

    // Update the date range when the selected start date or end date changes
  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
        saveRange();
    }
}, [selectedStartDate, selectedEndDate]);

  const resetDateRangeHandler = async () => {
    if (!docid) return;

   await db.collection(collection)
        .doc(docid)
        .update({ End: null, Start: null })

        // Update state
        setStartDate(null);
        setEndDate(null);
};


  // Period options handler
    const periodOptionsHandler = (e) => {
        const value = e.target.options[e.target.selectedIndex].value;

        switch (value) {
            case "all":
                resetDateRangeHandler();
                break;
                
            case "current-month":
                const currentMonth = new Date();
                const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
                const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
                setSelectedStartDate(firstDay);
                setSelectedEndDate(lastDay);
                break;
            
            case "last-month":
                const lastMonth = new Date();
                const firstDayLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1);
                const lastDayLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 0);
                setSelectedStartDate(firstDayLastMonth);
                setSelectedEndDate(lastDayLastMonth );
                break;

            case "current-year":
                const currentYear = new Date();
                const firstDayCurrentYear = new Date(currentYear.getFullYear(), 0, 1);
                const lastDayCurrentYear = new Date(currentYear.getFullYear(), 11, 31);
                setSelectedStartDate(firstDayCurrentYear );
                setSelectedEndDate(lastDayCurrentYear);
                break;

            case "last-year":
                const lastYear = new Date();
                const firstDayLastYear = new Date(lastYear.getFullYear() - 1, 0, 1);
                const lastDayLastYear = new Date(lastYear.getFullYear() - 1, 11, 31);
                setSelectedStartDate(firstDayLastYear);
                setSelectedEndDate(lastDayLastYear);
                break;

            case "custom":
                setShowRangePicker(true);
                break;

            default:
                break;
        }
    }

  return (
    <div id='period-selector-outer-container'>
        <select name="" id="period-selector-options-select" onChange={periodOptionsHandler}>
            <option value="default">-- Selecteer een periode --</option>
            <option value="all">Alles</option>
            <option value="current-month">Deze maand</option>
            <option value="last-month">Vorige maand</option>
            <option value="current-year">Dit jaar</option>
            <option value="last-year">Vorig jaar</option>
            <option value="custom" >Aangepast</option>
        </select>
        <div className="period-select-container">  
            <div className="period-select">
                <div id="selected-range-container">
                    {startDate ? (
                        <p>{startDate ? startDate.toDate().toLocaleDateString("nl-NL", options) : 'Alles'}</p>
                    ) : (
                        <p>Alles</p>
                    )}
                    <p>&nbsp; - &nbsp;</p>
                    <p>{endDate ? endDate.toDate().toLocaleDateString("nl-NL", options) : 'Alles'}</p>
                    <CalendarTodayOutlinedIcon
                        id="home-range-picker-icon"
                        onClick={() => setShowRangePicker(!showRangePicker)}
                    />
                </div>
                <div
                id="range-picker-container"
                style={{ display: showRangePicker ? "block" : "none" }}
                >
                <div>
                    <div className="range-picker-input-container">
                        <p>
                        <b>Start</b>
                        </p>
                        <input
                        type="date"
                        onChange={(e) => setSelectedStartDate(e.target.value)}
                        />
                    </div>
                    <div className="range-picker-input-container">
                        <p>
                        <b>Eind</b>
                        </p>
                        <input
                        type="date"
                        onChange={(e) => setSelectedEndDate(e.target.value)}
                        />
                    </div>

                </div>
                <div id='periodselector-card-button-container'>
                    <button onClick={() => setShowRangePicker(!showRangePicker)}>
                        Annuleren
                    </button>
                    <button  onClick={() => setShowRangePicker(!showRangePicker)}>
                        Opslaan
                    </button>
                </div>
              
            </div>
          </div>
        </div>
    </div>
  )
}

export default PeriodSelector